import React from 'react';

const NotFoundPage = () => (
  <div>
    <h1 className="not_found">
      This is not the page you were looking for…
    </h1>
  </div>
);

export default NotFoundPage;
